import React from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

import UserList from './user-list';

export default function Team({
  className = '',
  joinable = false,
  joining = false,
  name,
  onJoin = () => {},
  onRemoveUser = () => {},
  onSwapUser = () => {},
  reverse = false,
  users = [],
}) {
  const { css } = useFela({ reverse });
  const { t } = useTranslation();
  const headerClass = reverse ? 'text-right' : '';

  const joinText = joining
    ? 'platform.lobby.team.joining'
    : 'platform.lobby.team.join';

  return (
    <div className={className}>
      <h1 className={headerClass}>{name}</h1>
      <div className={css(styles.actionRow)}>
        {joinable && (
          <Button onClick={onJoin} outline disabled={joining}>
            {joining && <Spinner size='sm' />} {t(joinText)}{' '}
            <FontAwesomeIcon icon={faSignInAlt} />
          </Button>
        )}
      </div>
      <UserList
        onRemove={onRemoveUser}
        onSwap={onSwapUser}
        users={users}
        reverse={reverse}
      />
    </div>
  );
}

const styles = {
  actionRow: ({ reverse }) => ({
    display: 'flex',
    flexDirection: reverse ? 'row' : 'row-reverse',
    margin: '.5em 0',
    minHeight: '38px',
  }),
};
