import React from 'react';
import { arrayOf, bool, shape, func, string } from 'prop-types';

import User from './User';
import { ListGroup, ListGroupItem } from 'reactstrap';

export default function List({ className, onRemove, onSwap, users, reverse }) {
  return (
    <div className={className}>
      <ListGroup flush>
        {users.map(
          ({
            admin,
            avatarUrl,
            badge,
            hasBadge,
            id,
            name,
            removable,
            swappable,
          }) => (
            <ListGroupItem key={id}>
              <User
                admin={admin}
                avatarUrl={avatarUrl}
                badge={badge}
                hasBadge={hasBadge}
                key={id}
                name={name}
                onRemove={() => onRemove(id)}
                onSwap={() => onSwap(id)}
                removable={removable}
                reverse={reverse}
                swappable={swappable}
              />
            </ListGroupItem>
          )
        )}
      </ListGroup>
    </div>
  );
}
List.propTypes = {
  className: string,
  onRemove: func,
  onSwap: func,
  users: arrayOf(
    shape({
      id: string,
      ...User.propTypes,
    })
  ),
  reverse: bool,
};
List.defaultProps = {
  className: '',
  onRemove: (_id) => {},
  onSwap: (_id) => {},
  users: [],
  reverse: false,
};
