import { get } from 'lodash';

export default async (api, ...params) => {
  const response = await api(...params);
  const isError = get(response, 'data.isError', false);

  if (isError) {
    throw response;
  }

  return response;
};
