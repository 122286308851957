import {
  faHeart,
  faBug,
  faHatCowboy,
  faLink,
  faCameraRetro,
} from '@fortawesome/free-solid-svg-icons';
import { faGrinTongueWink } from '@fortawesome/free-regular-svg-icons';
import { faSuperpowers } from '@fortawesome/free-brands-svg-icons';

export default {
  DONATOR: {
    color: 'red',
    icon: faHeart,
    title: 'donator',
  },
  PRO_PLAYER: {
    color: 'red',
    icon: faSuperpowers,
    title: 'proPlayer',
  },
  STAFF: {
    color: 'red',
    icon: faBug,
    title: 'staff',
  },
  PROMOTER: {
    color: 'red',
    icon: faLink,
    title: 'promoter',
  },
  EARLY_BIRD: {
    color: 'red',
    icon: faHatCowboy,
    title: 'earlyBird',
  },
  FAN: {
    color: 'red',
    icon: faGrinTongueWink,
    title: 'fan',
  },
  INFLUENCER: {
    color: 'red',
    icon: faCameraRetro,
    title: 'influencer',
  },
};
