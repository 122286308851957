import React, { useEffect, useState } from 'react';
import {
  Row,
  Spinner,
  Container,
  Button,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';

import DiscordContactLink from '../../../../components/DiscordContactLink';

const TAKING_TOO_LONG_TIMEOUT = 180000;

export default function Loading({
  abortable,
  aborting,
  onAbort,
  onNotifyOnReadyChange = () => {},
  notifyOnReady = false,
}) {
  const { css } = useFela();
  const { t } = useTranslation();
  const [showDiscord, setShowDiscord] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowDiscord(true);
    }, TAKING_TOO_LONG_TIMEOUT);
  }, []);

  const abortText = aborting
    ? 'platform.lobby.modal.connection.aborting'
    : 'platform.lobby.modal.connection.abort';

  return (
    <div className='p-4'>
      <Row className='justify-content-center m-2'>
        <Spinner className={`${css(styles.spinner)}`} />
      </Row>
      <Row className='justify-content-center m-2'>
        <h5>{t('platform.lobby.modal.loading.header')}</h5>
      </Row>
      <Row className='justify-content-center m-2'>
        <p>{t('platform.lobby.modal.loading.usualTime')}</p>
      </Row>
      <Row className='justify-content-center m-2'>
        <FormGroup>
          <Label check>
            <Input
              type='checkbox'
              onChange={() => onNotifyOnReadyChange(!notifyOnReady)}
              checked={notifyOnReady}
              value='on'
            />{' '}
            {t('platform.lobby.modal.loading.notifyOnReady')}
          </Label>
        </FormGroup>
      </Row>
      {showDiscord && (
        <Row className='justify-content-center my-2 mt-4'>
          <p>
            {t('platform.lobby.modal.loading.takingTooLong')}{' '}
            <DiscordContactLink className='text-secondary' />
          </p>
        </Row>
      )}
      {abortable && (
        <>
          <hr />
          <Row className='justify-content-center'>
            <Container className='d-flex justify-content-between align-items-center'>
              {t('platform.lobby.modal.loading.somethingWrong')}{' '}
              <Button onClick={onAbort} color='danger' outline size='sm'>
                {aborting && <Spinner size='sm' />} {t(abortText)}
              </Button>
            </Container>
          </Row>
        </>
      )}
    </div>
  );
}

const styles = {
  spinner: {
    width: '3rem',
    height: '3rem',
  },
};
