import { CLOSED, ERROR, MESSAGE_RECEIVED, OPENED } from './actionTypes';

export const closed = () => ({
  type: CLOSED,
});

export const error = () => ({
  type: ERROR,
});

export const messageReceived = ({ message }) => ({
  type: MESSAGE_RECEIVED,
  payload: { message },
});

export const opened = () => ({
  type: OPENED,
});
