import {
  ABORT,
  CHANGE_MAP,
  CHANGE_TEAM,
  CHANGE_USER_TEAM,
  CLEAR_DATA,
  CLEAR_JOIN_ERROR,
  CREATE,
  FETCH_DATA,
  JOIN,
  JOIN_FROM_CODE_INPUT,
  JOINED,
  LEAVE,
  PAGE_LOADED,
  PAGE_UNLOADED,
  REMOVE_USER,
  RESET,
  SET_ABORTING,
  SET_CHANGING_MAP,
  SET_CHANGING_TEAM,
  SET_CHANGING_USER_TEAM,
  SET_CREATING,
  SET_DATA,
  SET_FETCHING,
  SET_JOIN_ERROR,
  SET_JOINING,
  SET_LEAVING,
  SET_LOADING,
  SET_REMOVING_USER,
  SET_STARTING_MATCH,
  SET_SUBSCRIBED,
  START_MATCH,
  SUBSCRIBE,
  UNSUBSCRIBE,
  UPDATE_RATING,
  SET_LOCAL_RATING,
  SET_UPDATING_RATING,
} from './actionTypes';

export const abort = () => ({
  type: ABORT,
});

export const changeMap = ({ id }) => ({
  type: CHANGE_MAP,
  payload: { id },
});

export const changeTeam = ({ id }) => ({
  type: CHANGE_TEAM,
  payload: { id },
});

export const changeUserTeam = ({ id }) => ({
  type: CHANGE_USER_TEAM,
  payload: { id },
});

export const clearData = () => ({
  type: CLEAR_DATA,
});

export const clearJoinError = () => ({
  type: CLEAR_JOIN_ERROR,
});

export const create = () => ({
  type: CREATE,
});

export const fetchData = ({ id }) => ({
  type: FETCH_DATA,
  payload: { id },
});

export const join = ({ id }) => ({
  type: JOIN,
  payload: { id },
});

export const joined = ({ data }) => ({
  type: JOINED,
  payload: { data },
});

export const joinFromCodeInput = ({ code }) => ({
  type: JOIN_FROM_CODE_INPUT,
  payload: { code },
});

export const leave = () => ({
  type: LEAVE,
});

export const pageLoaded = ({ lobbyId }) => ({
  type: PAGE_LOADED,
  payload: { lobbyId },
});

export const pageUnloaded = () => ({
  type: PAGE_UNLOADED,
});

export const updateRating = ({ rating }) => ({
  type: UPDATE_RATING,
  payload: { rating },
});

export const reset = () => ({
  type: RESET,
});

export const removeUser = ({ id }) => ({
  type: REMOVE_USER,
  payload: { id },
});

export const setAborting = ({ aborting }) => ({
  type: SET_ABORTING,
  payload: { aborting },
});

export const setChangingMap = ({ changingMap }) => ({
  type: SET_CHANGING_MAP,
  payload: { changingMap },
});

export const setChangingTeam = ({ changingTeam }) => ({
  type: SET_CHANGING_TEAM,
  payload: { changingTeam },
});

export const setChangingUserTeam = ({ changing, userId }) => ({
  type: SET_CHANGING_USER_TEAM,
  payload: {
    changing,
    userId,
  },
});

export const setCreating = ({ creating }) => ({
  type: SET_CREATING,
  payload: { creating },
});

export const setData = ({ data }) => ({
  type: SET_DATA,
  payload: { data },
});

export const setFetching = ({ fetching }) => ({
  type: SET_FETCHING,
  payload: { fetching },
});

export const setLoading = ({ loading }) => ({
  type: SET_LOADING,
  payload: { loading },
});

export const setLocalRating = ({ localRating }) => ({
  type: SET_LOCAL_RATING,
  payload: { localRating },
});

export const setJoinError = ({ error }) => ({
  type: SET_JOIN_ERROR,
  payload: { error },
});

export const setJoining = ({ joining }) => ({
  type: SET_JOINING,
  payload: { joining },
});

export const setLeaving = ({ leaving }) => ({
  type: SET_LEAVING,
  payload: { leaving },
});

export const setRemovingUser = ({ removing, userId }) => ({
  type: SET_REMOVING_USER,
  payload: { removing, userId },
});

export const setStartingMatch = ({ startingMatch }) => ({
  type: SET_STARTING_MATCH,
  payload: { startingMatch },
});

export const setSubscribed = ({ subscribed }) => ({
  type: SET_SUBSCRIBED,
  payload: { subscribed },
});

export const setUpdatingRating = ({ updatingRating }) => ({
  type: SET_UPDATING_RATING,
  payload: { updatingRating },
});

export const startMatch = () => ({
  type: START_MATCH,
});

export const subscribe = ({ matchId }) => ({
  type: SUBSCRIBE,
  payload: { matchId },
});

export const unsubscribe = () => ({
  type: UNSUBSCRIBE,
});
