import { eventChannel, END } from 'redux-saga';

import { closed, error, messageReceived, opened } from './actions';
import { getConnectionUrl } from './helper';

export default ({ token, matchId }) =>
  eventChannel((emitter) => {
    const connectionUrl = getConnectionUrl({ token });
    const socket = new WebSocket(connectionUrl);

    socket.onopen = () => {
      socket.send(
        JSON.stringify({ action: 'SUBSCRIBE', resource: 'MATCH', id: matchId })
      );

      setInterval(() => {
        socket.send(JSON.stringify({ action: 'PING' }));
      }, 9 * 60 * 1000);

      emitter(opened());
    };

    socket.onclose = () => {
      emitter(closed());
      emitter(END);
    };

    socket.onmessage = ({ data }) => {
      const message = JSON.parse(data);
      emitter(messageReceived({ message }));
    };

    socket.onerror = () => {
      emitter(error());
    };

    return () => {
      socket.close(1000, 'User connection terminated.');
    };
  });
