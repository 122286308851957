import { createSelector } from 'reselect';
import { get, filter, find, map } from 'lodash';

import { getOngoingLobbies as getBasicOngoingLobbies } from './basic';
import availableMaps from '../../../lobby/helper/availableMaps';

const LEFT_TEAM = 'TEAM_1';
const RIGHT_TEAM = 'TEAM_2';

export const getOngoingLobbies = createSelector(
  getBasicOngoingLobbies,
  (ongoingLobbies) => map(ongoingLobbies, mapOngoingLobby)
);

const mapOngoingLobby = (lobby) => ({
  lobbyId: getLobbyId(lobby),
  leftTeam: getLobbyTeam(LEFT_TEAM, lobby),
  rightTeam: getLobbyTeam(RIGHT_TEAM, lobby),
  map: getLobbyMap(lobby),
});

const getLobbyId = ({ lobbyIdentifier }) => lobbyIdentifier;
const getLobbyTeam = (team, { players }) => {
  const teamPlayers = filter(players, { team });
  return map(teamPlayers, mapPlayer);
};
const mapPlayer = (player) => ({
  avatar: get(player, 'user.avatar.small', ''),
  name: get(player, 'user.nickname', ''),
});
const getLobbyMap = ({ map: mapId }) => {
  const mapEntry = find(availableMaps, { id: mapId });

  return get(mapEntry, 'name', '?');
};
