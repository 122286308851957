import { put, takeEvery } from 'redux-saga/effects';

import { PAGE_UNLOADED } from '../actionTypes';
import { unsubscribe } from '../actions';

export default function* pageUnloaded() {
  yield put(unsubscribe());
}

export function* watchPageUnloaded() {
  yield takeEvery(PAGE_UNLOADED, pageUnloaded);
}
