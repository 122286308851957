import { takeEvery, put } from 'redux-saga/effects';

import { UNSUBSCRIBE } from '../actionTypes';
import { setSubscribed } from '../actions';

export default function* unsubscribe() {
  yield put(setSubscribed({ subscribed: false }));
}

export function* watch() {
  yield takeEvery(UNSUBSCRIBE, unsubscribe);
}
