import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { CHANGE_USER_TEAM } from '../actionTypes';
import { setChangingUserTeam, setData } from '../actions';
import { getLobbyId } from '../selectors';
import {
  getOpposingTeam,
  getPlayerTeam,
  getPlayerByUserId,
} from '../selectors/room';
import { updateLobbyChangeTeam } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';

export default function* changeUserTeam({ payload: { id: userId } }) {
  const matchId = yield select(getLobbyId);
  const player = yield select(getPlayerByUserId, userId);

  if (!player) return;

  const playerTeam = getPlayerTeam(player);
  const teamId = getOpposingTeam(playerTeam);

  yield put(setChangingUserTeam({ changing: true, userId }));

  try {
    const { data } = yield call(consistentlyRequest, updateLobbyChangeTeam, {
      matchId,
      teamId,
      userId,
    });

    yield put(setData({ data }));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.changeUserTeam'));
  } finally {
    yield put(setChangingUserTeam({ changing: false, userId }));
  }
}

export function* watchChangeUserTeam() {
  yield takeEvery(CHANGE_USER_TEAM, changeUserTeam);
}
