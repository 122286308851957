import api from './api';

export const getMatchesByUserId = () => api.get('match/by_user_id', {});

export const createLobby = () => api.post('lobby/create', {});

export const joinLobby = ({ id }) => api.post('lobby/join', { lobbyId: id });

export const updateLobbyQuit = ({ matchId }) =>
  api.post('lobby/quit', { matchId });

export const updateLobbyKick = ({ matchId, userId }) =>
  api.post('lobby/kick', { matchId, userId });

export const updateLobbyChangeMap = ({ matchId, map, mapType = undefined }) =>
  api.post('lobby/change_map', { matchId, map, mapType });

export const updateLobbyChangeTeam = ({ matchId, userId, teamId }) =>
  api.post('lobby/change_team', { matchId, userId, teamId });

export const updateMatchStartServer = ({ matchId }) =>
  api.post('match/start_server', { matchId });

export const updateMatchAbort = ({ matchId }) =>
  api.post('match/abort', { matchId });

export const getMatchById = ({ matchId }) =>
  api.get('match/by_id', { params: { matchId } });

export const addRating = ({ matchId, score, message = '' }) =>
  api.post('match/add_rating', { matchId, score, message });
