import React, { useState } from 'react';
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { func, string } from 'prop-types';

export default function JoinLobby({ className, onJoin }) {
  const { t } = useTranslation();

  const [code, setCode] = useState('');

  const onCodeChange = ({ target: { value } }) => setCode(value);
  const onJoinClick = () => onJoin(code);

  return (
    <div className={className}>
      <h2>{t('platform.home.actions.joinLobby.joinLobby')}</h2>
      <div>
        <InputGroup>
          <Input
            placeholder={t('platform.home.actions.joinLobby.lobbyCode')}
            value={code}
            onChange={onCodeChange}
          />
          <InputGroupAddon addonType='append'>
            <Button onClick={onJoinClick}>
              {t('platform.home.actions.joinLobby.join')}
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    </div>
  );
}
JoinLobby.propTypes = {
  className: string,
  onJoin: func.isRequired,
};
JoinLobby.defaultProps = {
  className: '',
};
