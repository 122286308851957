import React from 'react';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';

export default function Or() {
  const { css } = useFela();
  const { t } = useTranslation();

  return (
    <div className={css(styles.container)}>
      <h4 className={css(styles.heading)}>{t('platform.home.actions.or')}</h4>
    </div>
  );
}

const styles = {
  container: {
    margin: '0 2.5em',
    display: 'flex',
    alignItems: 'center',
  },
  heading: {
    color: 'grey',
    fontSize: '2rem',
  },
};
