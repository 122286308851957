import React from 'react';
import { Button, Card, CardSubtitle, CardTitle, Spinner } from 'reactstrap';
import { useFela } from 'react-fela';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export default function PlanCard({
  billingInterval,
  buttonColor,
  buttonText,
  name,
  tagline,
  disabled,
  onSubscribe = () => {},
  price = 0,
  loading = false,
  perks = [],
}) {
  const { css } = useFela();

  const styles = {
    container: {},
    perkItem: {
      color: '#404040',
    },
  };

  const currencyFormat = (num) =>
    num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  return (
    <Card body className={`${css(styles.container)} my-2`}>
      <CardTitle tag='h5' className=''>
        {name}
      </CardTitle>
      <CardSubtitle tag='p' className='mb-3 text-muted'>
        {tagline}
      </CardSubtitle>
      <span className='my-3'>
        <span className='h3 d-inline-block'>R$ {currencyFormat(price)}</span>/
        {billingInterval}
      </span>
      <Button
        color={buttonColor}
        disabled={loading || disabled}
        onClick={onSubscribe}
      >
        {loading && <Spinner size='sm' />} {buttonText}
      </Button>
      <hr />
      <ul className='list-unstyled'>
        {perks.map(({ icon = faCheck, text }) => (
          <li key={text} className={`my-2 ${css(styles.perkItem)}`}>
            <FontAwesomeIcon className='mx-2' icon={icon} /> {text}
          </li>
        ))}
      </ul>
    </Card>
  );
}
