import React from 'react';
import { bool, string } from 'prop-types';

export default function Avatar({ alt, className, circle, url }) {
  const styleClass = circle ? 'rounded-circle' : '';
  const classNames = `${className} ${styleClass}`;

  return <img className={classNames} src={url} alt={alt} />;
}
Avatar.propTypes = {
  alt: string.isRequired,
  circle: bool,
  className: string,
  url: string.isRequired,
};
Avatar.defaultProps = {
  circle: true,
  className: '',
};
