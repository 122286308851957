import { takeEvery, take, call, select, put } from 'redux-saga/effects';

import { SUBSCRIBE } from '../actionTypes';
import { setSubscribed } from '../actions';
import { getToken } from '../../../../authentication/store/selectors';
import { getSubscribed } from '../selectors';

import subscribeToSocket from './socket/channel';
import messageHandler from './socket/messageHandler';

export default function* subscribe({ matchId }) {
  const token = yield select(getToken);
  const channel = yield call(subscribeToSocket, { token, matchId });

  yield put(setSubscribed({ subscribed: true }));

  try {
    while (yield select(getSubscribed)) {
      const event = yield take(channel);
      yield call(messageHandler, event);
    }
  } catch (error) {
    console.log('error while reading websocket messages:');
    console.error(error);
  }
}

export function* watch() {
  yield takeEvery(SUBSCRIBE, function* ({ payload }) {
    yield call(subscribe, payload);
  });
}
