import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import { useFela } from 'react-fela';
import { groupBy, maxBy, sortBy } from 'lodash';

import useMapSearch from './map-search/useMapSearch';
import i18n from '../../../../i18n';

export default function MapSelect({
  className = '',
  currentMapId,
  disabled = false,
  maps = [],
  onChange = () => {},
}) {
  const { t } = useTranslation();
  const { css } = useFela();
  const [isOpen, setIsOpen] = useState();
  const [search, setSearch] = useState('');
  const searchResults = useMapSearch({
    maps,
    search,
  });

  const toggleDropdown = () => {
    if (isOpen) setSearch('');

    setIsOpen(!isOpen);
  };

  const currentMap = maps.find(({ id }) => id === currentMapId);

  const renderItem = ({ item: { id, name, tags } }, index) => {
    const active = id === currentMapId;
    const activeBackground = (active && 'bg-secondary') || '';
    const disabledActiveText = (disabled && active && 'text-white') || '';
    const className = `${activeBackground} ${disabledActiveText}`;

    return (
      <DropdownItem
        onClick={() => onChange(id)}
        disabled={disabled}
        active={active}
        className={className}
        key={id}
        tabIndex={index}
      >
        {name}
        {renderBadges(tags)}
      </DropdownItem>
    );
  };
  const renderCategory = (category) => (
    <DropdownItem header className={css(styles.categoryItem)} key={category}>
      {t(`platform.lobby.options.map.categories.${category}`)}
    </DropdownItem>
  );

  const categories = groupBy(searchResults, 'item.category');
  const byNameCategories = sortBy(categories, (_, name) =>
    t(`platform.lobby.options.map.categories.${name}`)
  );
  const items = sortBy(byNameCategories, (category) =>
    maxBy(category, 'score')
  );

  return (
    <ButtonDropdown
      className={`${className} ${css(styles.dropdownButton)}`}
      isOpen={isOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle caret outline>
        {currentMap.name}
        {renderBadges(currentMap.tags)}
      </DropdownToggle>
      <DropdownMenu className={css(styles.menu)}>
        <Form className='px-2' tag='div'>
          <FormGroup>
            <Input
              autoFocus
              placeholder={t('platform.lobby.options.map.search')}
              value={search}
              onChange={({ currentTarget: { value } }) => {
                setSearch(value);
              }}
            />
          </FormGroup>
        </Form>
        <div className={css(styles.itemsContainer)}>
          {items.flatMap((items) => [
            renderCategory(items[0].item.category),
            items.map(renderItem),
          ])}
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

const styles = {
  dropdownButton: {
    display: 'block',
  },
  menu: {
    width: '250px',
  },
  itemsContainer: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
  categoryItem: {
    color: '#9a2f2f',
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
  },
};

const renderBadges = (badges) =>
  badges.map((tag) => {
    const name = typeof tag === 'string' ? tag : tag.tag;
    const color = tag.color || undefined;

    const text = i18n.t(`platform.lobby.options.map.tags.${name}`);

    return (
      <Badge className='mx-1' color={color} key={name}>
        {text}
      </Badge>
    );
  });
