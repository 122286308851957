import {
  CLEAR_REDIRECT_PATH,
  CLEAR_TOKEN,
  CLEAR_TOKEN_DATA,
  CLEAR_USER_DATA,
  SET_REDIRECT_PATH,
  SET_TOKEN,
  SET_TOKEN_DATA,
  SET_USER_DATA,
  SET_USER_DATA_LOADING,
} from './actionTypes';

export const initialState = {
  redirectPath: undefined,
  token: undefined,
  tokenData: undefined,
  userData: undefined,
  userDataLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_REDIRECT_PATH:
      return clearRedirectPath(state, action);
    case CLEAR_TOKEN:
      return clearToken(state, action);
    case CLEAR_TOKEN_DATA:
      return clearTokenData(state, action);
    case CLEAR_USER_DATA:
      return clearUserData(state, action);
    case SET_REDIRECT_PATH:
      return setRedirectPath(state, action);
    case SET_TOKEN:
      return setToken(state, action);
    case SET_TOKEN_DATA:
      return setTokenData(state, action);
    case SET_USER_DATA:
      return setUserData(state, action);
    case SET_USER_DATA_LOADING:
      return setUserDataLoading(state, action);
    default:
      return state;
  }
};

const clearRedirectPath = (state, _action) => ({
  ...state,
  redirectPath: undefined,
});

const clearToken = (state, _action) => ({
  ...state,
  token: undefined,
});

const clearTokenData = (state, _action) => ({
  ...state,
  tokenData: undefined,
});

const clearUserData = (state, _action) => ({
  ...state,
  userData: undefined,
});

const setRedirectPath = (state, action) => {
  const {
    payload: { path: redirectPath },
  } = action;

  return {
    ...state,
    redirectPath,
  };
};

const setToken = (state, action) => {
  const {
    payload: { token },
  } = action;

  return {
    ...state,
    token,
  };
};

const setTokenData = (state, action) => {
  const {
    payload: { tokenData },
  } = action;

  return {
    ...state,
    tokenData,
  };
};

const setUserData = (state, action) => {
  const {
    payload: { userData },
  } = action;

  return {
    ...state,
    userData,
  };
};

const setUserDataLoading = (state, action) => {
  const {
    payload: { loading },
  } = action;

  return {
    ...state,
    userDataLoading: loading,
  };
};
