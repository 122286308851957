import React from 'react';
import { string } from 'prop-types';
import { Col, ListGroupItem, Row } from 'reactstrap';

import Users from './Users';

export default function Item({ leftTeam, rightTeam, map, lobbyId }) {
  return (
    <ListGroupItem tag='a' action href={`/${lobbyId}`}>
      <Row className='justify-content-center'>
        <Col sm='auto'>
          <Users users={leftTeam} />
        </Col>
        <Col sm='auto'>{map}</Col>
        <Col sm='auto'>
          <Users users={rightTeam} />
        </Col>
      </Row>
    </ListGroupItem>
  );
}
Item.propTypes = {
  leftTeam: Users.propTypes.users,
  lobbyId: string.isRequired,
  map: string.isRequired,
  rightTeam: Users.propTypes.users,
};
Item.defaultProps = {};
