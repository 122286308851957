import React, { useEffect, useState } from 'react';
import { useFela } from 'react-fela';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Navbar,
  NavbarBrand,
  Row,
  UncontrolledCarousel,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import AnimatedNumber from 'animated-number-react';

import Footer from '../../../components/Footer';

import csBackground from '../../../assets/cs-background.jfif';
import demoVideo from '../../../assets/fastdemo-small.mp4';

import bikiniBottom from '../../../assets/bikini-bottom.jpg';
import infernoMirror from '../../../assets/inferno-mirror.jpg';
import infernoOld from '../../../assets/inferno-old.jpg';
import cacheOld from '../../../assets/cache-old.jpg';
import shortDust from '../../../assets/short-dust.png';

import leo from '../../../assets/leo.jpg';
import ali from '../../../assets/ali.jpg';

import LandingPlanCard from '../../../app/membership/components/LandingPlanCard';

import logo from '../../../assets/qs24.png';

const STEAM_SIGN_IN_URL = process.env.REACT_APP_STEAM_SIGN_IN_URL;
const STATISTICS_URL = process.env.REACT_APP_STATISTICS_URL;

const carouselItems = [
  {
    src: bikiniBottom,
    altText: '',
    caption: '',
    header: '',
    key: 'bikini',
  },
  {
    src: infernoMirror,
    altText: '',
    caption: '',
    header: '',
    key: 'inferno',
  },
  {
    src: infernoOld,
    altText: '',
    caption: '',
    header: '',
    key: 'inferno-old',
  },
  {
    src: cacheOld,
    altText: '',
    caption: '',
    header: '',
    key: 'cache-old',
  },
  {
    src: shortDust,
    altText: '',
    caption: '',
    header: '',
    key: 'short-dust',
  },
];

const defaultOnSubscribe = () => {
  window.location.href = STEAM_SIGN_IN_URL;
};

const STARTING_STATISTICS = {
  userCount: 1700,
  minutesOfGameplay: 40000,
  matchCount: 1200,
};
const SUBSCRIPTIONS = [
  { type: 'free', price: 0, disabled: false },
  { type: 'gold', price: 15.9, disabled: true },
];

export default ({
  subscriptionLoading = false,
  subscription = 'free',
  subscriptions = SUBSCRIPTIONS,
  onSubscribe = defaultOnSubscribe,
}) => {
  const { css } = useFela();
  const { t } = useTranslation();

  const [statistics, setStatistics] = useState(STARTING_STATISTICS);

  useEffect(() => {
    fetch(STATISTICS_URL)
      .then((response) => response.json())
      .then(({ nUsers, totalMatchTimeInMinutes, nMatches }) => {
        setStatistics({
          userCount: nUsers,
          minutesOfGameplay: totalMatchTimeInMinutes,
          matchCount: nMatches,
        });
      });
  }, []);

  return (
    <div className={css(styles.container)}>
      <Navbar>
        <Container>
          <NavbarBrand tag={Link} to='/'>
            <img
              className={css(styles.navbarLogo)}
              src={logo}
              alt={t('navbar.logo.alt')}
            />
          </NavbarBrand>
          <Button color='primary' outline tag='a' href={STEAM_SIGN_IN_URL}>
            Entrar com a Steam <FontAwesomeIcon icon={faSteam} />
          </Button>
        </Container>
      </Navbar>
      <Container>
        <Row className='my-5'>
          <Row>
            <Col className='d-flex' lg='6'>
              <div className='p-4' style={{ alignSelf: 'center' }}>
                <h2>Partidas de CS:GO na nuvem</h2>
                <p>
                  Servidores poderosos, <strong>gratuitos</strong>, a cliques de
                  distância.
                </p>
                <Button color='primary' tag='a' href={STEAM_SIGN_IN_URL}>
                  Comece agora com a Steam <FontAwesomeIcon icon={faSteam} />
                </Button>
              </div>
            </Col>
            <Col lg='6'>
              <video
                className={`p-4 ${css(styles.video)}`}
                autoPlay
                controls
                controlsList='nodownload nofullscreen'
              >
                <source src={demoVideo} />
              </video>
            </Col>
          </Row>
        </Row>
      </Container>
      <Container>
        <Row className='my-5'>
          <Row>
            <Col className='my-4' md='4'>
              <h3>128 tickrate, 0 var</h3>
              <p>
                Nos nossos servidores{' '}
                <em>otimizados para a mais alta performance</em>, toda bala
                encaixa.
              </p>
            </Col>
            <Col className='my-4' md='4'>
              <h3>A todo instante</h3>
              <p>
                Com a escalabilidade <em>virtualmente infinita</em> da nuvem{' '}
                <em>sempre</em> temos uma sala para você.
              </p>
            </Col>
            <Col className='my-4' md='4'>
              <h3>Em um instante</h3>
              <p>
                Juntar o clã é tão <em>simples</em> quanto compartilhar um link.
                É sério.
              </p>
            </Col>
          </Row>
        </Row>
      </Container>
      <div className='bg-light'>
        <Container>
          <Row className='my-5'>
            <Col>
              <h3 className='text-center'>Uma comunidade em crescimento</h3>
              <Row className='mt-4'>
                <Col md='4'>
                  <div className='p-2 d-flex flex-column align-items-center'>
                    <span className='h3'>
                      <AnimatedNumber
                        value={statistics.userCount}
                        formatValue={(v) => parseInt(v)}
                      />
                    </span>
                    <span>jogadores cadastrados</span>
                  </div>
                </Col>
                <Col md='4'>
                  <div className='p-2 d-flex flex-column align-items-center'>
                    <span className='h3'>
                      <AnimatedNumber
                        value={statistics.minutesOfGameplay}
                        formatValue={(v) => parseInt(v)}
                      />
                    </span>
                    <span>minutos de partida</span>
                  </div>
                </Col>
                <Col md='4'>
                  <div className='p-2 d-flex flex-column align-items-center'>
                    <span className='h3'>
                      <AnimatedNumber
                        value={statistics.matchCount}
                        formatValue={(v) => parseInt(v)}
                      />
                    </span>
                    <span>partidas jogadas</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className='my-5'>
          <Col lg='6'>
            <UncontrolledCarousel className='' items={carouselItems} />
          </Col>
          <Col lg='6' className='p-md-4 my-md-0 my-4 d-flex'>
            <div style={{ alignSelf: 'center' }}>
              <h3>Tem competitivo, tem zueira</h3>
              <p>
                Além dos mapas competitivos que já são de se esperar, temos
                mapas <em>for fun</em>, 3V3 e braço direito. Ainda não está
                convencido de que essa é a melhor plataforma pro seu mix? E se
                eu te contar que temos as versões antigas da Inferno, Nuke,
                Train, Cobble...
              </p>
              <Button color='primary' outline tag='a' href={STEAM_SIGN_IN_URL}>
                Vem testar! <FontAwesomeIcon icon={faSteam} />
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='my-5'>
          <Col>
            <h3 className='text-center'>
              Benefícios exclusivos para apoiadores
            </h3>
            <Container className='my-4 d-flex flex-column'>
              <Row className='justify-content-center'>
                {subscriptions.map(({ price, type, disabled }) => (
                  <Col key={type} xs='12' md='6' lg='4' className=''>
                    <LandingPlanCard
                      key={type}
                      current={subscription === type}
                      onSubscribe={() => onSubscribe({ plan: type })}
                      price={price}
                      type={type}
                      disabled={disabled}
                      loading={subscriptionLoading && !(subscription === type)}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <div className='bg-light'>
        <Container>
          <Row className='my-5'>
            <Col lg='9' className='mx-auto'>
              <div>
                <h3 className='text-center'>
                  Criada por jogadores, para jogadores
                </h3>
                <div className='my-2 d-flex flex-row align-items-center justify-content-between'>
                  <img
                    src={leo}
                    alt='leo'
                    style={{ maxHeight: '8em', borderRadius: '100%' }}
                    className='m-2'
                  />
                  <div className='m-2'>
                    <p className='mb-0'>
                      Criamos a plataforma pois queríamos uma forma{' '}
                      <em>simples</em> de criar servidores de{' '}
                      <em>alta qualidade</em> para mix com nossos amigos, sem
                      ter que lidar com restrições arbitrárias e anti-cheats
                      invasivos como em outras plataformas.
                    </p>
                    <footer className='blockquote-footer'>Leonardo</footer>
                  </div>
                </div>

                <div className='my-2 d-flex flex-row align-items-center justify-content-between'>
                  <div className='m-2 text-right'>
                    <p className='mb-0'>
                      Somos desenvolvedores de software de dia, jogadores de CS
                      a noite. Com uma década de experiência combinada em
                      desenvolvimento de software, pode ter certeza que essa não
                      é a primeira plataforma <em>segura e resiliente</em> pela
                      qual somos responsáveis.
                    </p>
                    <footer className='blockquote-footer'>Ali</footer>
                  </div>
                  <img
                    src={ali}
                    alt='ali'
                    style={{ maxHeight: '8em', borderRadius: '100%' }}
                    className='m-2'
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

const styles = {
  video: {
    maxWidth: '100%',
  },
  container: () => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  }),
  homeSection: () => ({
    background: `url(${csBackground})`,
    backgroundSize: 'cover',
  }),
  footerSection: () => ({
    backgroundColor: '#0b0c10',
    height: 'auto',
    flexGrow: 1,
    color: '#444',
  }),
  navbarLogo: () => ({
    height: '40px',
  }),
};
