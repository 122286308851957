import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { START_MATCH } from '../actionTypes';
import { setStartingMatch, setData } from '../actions';
import { getLobbyId } from '../selectors';
import { updateMatchStartServer } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';

export default function* startMatch() {
  const matchId = yield select(getLobbyId);

  yield put(setStartingMatch({ startingMatch: true }));

  try {
    const { data } = yield call(consistentlyRequest, updateMatchStartServer, {
      matchId,
    });

    yield put(setData({ data }));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.startMatch'));
  } finally {
    yield put(setStartingMatch({ startingMatch: false }));
  }
}

export function* watchStartMatch() {
  yield takeEvery(START_MATCH, startMatch);
}
