import React, { useState } from 'react';
import { useFela } from 'react-fela';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuid } from 'uuid';

export default function ActionIcon({
  className = '',
  clickable = false,
  icon,
  id,
  onClick = () => {},
  tooltipPlacement = 'top',
  tooltipText,
  color,
}) {
  const { css } = useFela({ clickable });
  const [iconId] = useState(id || generateId);
  const colorClass = color && `text-${color}`;

  return (
    <div className={`${className} ${css(styles.icon)}`}>
      <FontAwesomeIcon
        className={colorClass}
        icon={icon}
        id={iconId}
        onClick={onClick}
      />
      <UncontrolledTooltip placement={tooltipPlacement} target={iconId}>
        {tooltipText}
      </UncontrolledTooltip>
    </div>
  );
}

const styles = {
  icon: ({ clickable }) => ({
    cursor: clickable ? 'pointer' : 'auto',
    display: 'inline',
  }),
};

const generateId = () => `ai-${uuid()}`;
