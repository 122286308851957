import React from 'react';
import {
  Button,
  Container,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import Rating from './Rating';
import DiscordContactInfo from '../../../components/DiscordContactLink';
import InstagramInfo from '../../../components/InstagramInfo';
import { useFela } from 'react-fela';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const HOME_PATH = '/';

export default function FinishedModal({
  askForRating = false,
  className = '',
  creatingNewLobby = false,
  backdrop = 'static',
  keyboard = false,
  open = false,
  rating = undefined,
  ratingLoading = false,
  demoState = 'unavailable',
  demoUrl,
  onRatingChange = (_rating) => {},
  onNewLobby = () => {},
}) {
  const { t } = useTranslation();
  const { css } = useFela();

  const newLobbyButtonText = t(
    creatingNewLobby
      ? 'platform.lobby.modal.finished.creatingLobby'
      : 'platform.lobby.modal.finished.newLobby'
  );

  return (
    <Modal
      className={className}
      isOpen={open}
      backdrop={backdrop}
      keyboard={keyboard}
    >
      <ModalBody>
        <Container>
          <h4 className='mt-2 mb-4'>
            {t('platform.lobby.modal.finished.header')}
          </h4>

          <div class='d-flex justify-content-center'>
            <a size='sm' href={demoUrl}>
              {demoState === 'loading' && <Spinner size='sm' />}{' '}
              {t(
                {
                  unavailable: 'platform.lobby.modal.demo.unavailable',
                  available: 'platform.lobby.modal.demo.download',
                  loading: 'platform.lobby.modal.demo.loading',
                }[demoState] || 'demo unavailable'
              )}
            </a>
            <a
              id='demo-info'
              class='mx-2'
              href={t('platform.lobby.modal.demo.helpUrl')}
              target='_blank'
              rel='noopener noreferrer'
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
            </a>
            <UncontrolledTooltip target='demo-info' placement='top'>
              {t('platform.lobby.modal.demo.helpTooltip')}
            </UncontrolledTooltip>
          </div>

          {askForRating ? (
            <>
              <hr />
              <Rating
                loading={ratingLoading}
                value={rating}
                onChange={onRatingChange}
              />
            </>
          ) : (
            <p className='my-2'>
              {t('platform.lobby.modal.finished.thankYou')}
            </p>
          )}

          <div className='mt-4 mb-2 d-flex justify-content-between'>
            <SocialMedia
              className={css(styles.socialMediaContainer)}
              iconsClassName={`text-secondary ${css(styles.socialMediaIcons)}`}
            />

            <div>
              <Button tag='a' color='link' href={HOME_PATH}>
                {t('platform.lobby.modal.finished.goBackHome')}
              </Button>
              <Button
                color='success'
                outline
                onClick={onNewLobby}
                disabled={creatingNewLobby}
              >
                {creatingNewLobby && <Spinner size='sm' />} {newLobbyButtonText}
              </Button>
            </div>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
}

const styles = {
  socialMediaContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    justifySelf: 'flex-start',
  },
  socialMediaIcons: {
    margin: '0 .25em',
  },
};

function SocialMedia({ className = '', iconsClassName = '' }) {
  return (
    <div className={className}>
      <DiscordContactInfo className={iconsClassName} noText />
      <InstagramInfo className={iconsClassName} noText />
    </div>
  );
}
