import { get } from 'lodash';

import { getApp } from '../../../store/selectors';

export const getAborting = (state) => getLobby(state).aborting;

export const getChangingMap = (state) => getLobby(state).changingMap;

export const getChangingTeam = (state) => getLobby(state).changingTeam;

export const getCreating = (state) => getLobby(state).creating;

export const getData = (state) => getLobby(state).data;

export const getFetching = (state) => getLobby(state).fetching;

export const getJoinError = (state) => getLobby(state).joinError;

export const getJoining = (state) => getLobby(state).joining;

export const getLeaving = (state) => getLobby(state).leaving;

export const getLoading = (state) => getLobby(state).loading;

export const getLobby = (state) => getApp(state).lobby;

export const getLobbyShortId = (state) =>
  get(getData(state), 'lobbyIdentifier');

export const getLobbyId = (state) => get(getData(state), '_id');

export const getStartingMatch = (state) => getLobby(state).startingMatch;

export const getSubscribed = (state) => getLobby(state).subscribed;

export const hasData = (state) => !!getData(state);
