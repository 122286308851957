import React from 'react';
import { string, bool, func } from 'prop-types';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';
import {
  faBan,
  faExchangeAlt,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

import Avatar from '../../../components/Avatar';
import ActionIcon from './ActionIcon';
import BadgedAvatar from '../../../components/BadgedAvatar';

export default function User({
  admin,
  avatarUrl,
  badge,
  hasBadge,
  name,
  onRemove,
  onSwap,
  removable,
  reverse,
  swappable,
}) {
  const { css } = useFela({ reverse });
  const { t } = useTranslation();

  return (
    <div className={css(styles.container)}>
      {hasBadge ? (
        <BadgedAvatar
          alt={name}
          badge={badge}
          className={css(styles.avatar)}
          url={avatarUrl}
        />
      ) : (
        <Avatar alt={name} className={css(styles.avatar)} url={avatarUrl} />
      )}
      <span className={css(styles.item)}>{name}</span>
      {admin && (
        <ActionIcon
          className={css(styles.item, styles.actionIcon)}
          icon={faStar}
          tooltipText={t('platform.lobby.team.user.admin')}
        />
      )}
      {removable && (
        <ActionIcon
          className={css(styles.item, styles.actionIcon)}
          clickable
          icon={faBan}
          onClick={onRemove}
          tooltipText={t('platform.lobby.team.user.remove')}
        />
      )}
      {swappable && (
        <ActionIcon
          className={css(styles.item, styles.actionIcon)}
          clickable
          icon={faExchangeAlt}
          onClick={onSwap}
          tooltipText={t('platform.lobby.team.user.swap')}
        />
      )}
    </div>
  );
}
User.propTypes = {
  admin: bool,
  avatarUrl: string.isRequired,
  hasBadge: bool,
  name: string.isRequired,
  onRemove: func,
  onSwap: func,
  removable: bool,
  reverse: bool,
  swappable: bool,
};
User.defaultProps = {
  admin: false,
  onRemove: () => {},
  onSwap: () => {},
  removable: false,
  reverse: false,
  swappable: false,
};

const styles = {
  container: ({ reverse }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: reverse ? 'row-reverse' : 'row',
  }),
  avatar: {
    margin: '0 .4em',
  },
  item: {
    margin: '0 .2em',
  },
  adminIcon: {
    color: '#ccc',
  },
  actionIcon: {
    color: '#aaa',
  },
};
