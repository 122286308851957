import { put, takeEvery } from 'redux-saga/effects';

import { PAGE_LOADED } from '../actionTypes';
import { join } from '../actions';

export default function* pageLoaded({ payload: { lobbyId } }) {
  yield put(join({ id: lobbyId }));
}

export function* watchPageLoaded() {
  yield takeEvery(PAGE_LOADED, pageLoaded);
}
