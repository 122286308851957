import { connect } from 'react-redux';

import {
  getCurrentMap,
  getOptionsDisabled,
  getTeams,
  getUnstartable,
  getUnstartableReasons,
  getUrl,
} from '../store/selectors/room';
import { isFinished, isFinishing } from '../store/selectors/page';
import {
  getChangingMap,
  getChangingTeam,
  getLeaving,
  getStartingMatch,
} from '../store/selectors';
import {
  changeMap,
  changeTeam,
  changeUserTeam,
  leave,
  removeUser,
  startMatch,
} from '../store/actions';

import availableMaps from '../helper/availableMaps';

import Room from '../components/Room';

const mapStateToProps = (state) => ({
  availableMaps,
  changingMap: getChangingMap(state),
  changingTeams: getChangingTeam(state),
  currentMap: getCurrentMap(state),
  finished: isFinished(state),
  finishing: isFinishing(state),
  leaving: getLeaving(state),
  optionsDisabled: getOptionsDisabled(state),
  starting: getStartingMatch(state),
  teams: getTeams(state),
  unstartableReasons: getUnstartableReasons(state),
  unstartable: getUnstartable(state),
  url: getUrl(state),
});
const mapDispatchToProps = (dispatch) => ({
  onJoinTeam: (id) => dispatch(changeTeam({ id })),
  onLeave: () => dispatch(leave()),
  onMapChange: (id) => dispatch(changeMap({ id })),
  onRemoveUser: (id) => dispatch(removeUser({ id })),
  onStart: () => dispatch(startMatch()),
  onSwapUser: (id) => dispatch(changeUserTeam({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);
