import React from 'react';
import { string } from 'prop-types';
import { useFela } from 'react-fela';

export default function Avatar({ image, name }) {
  const { css } = useFela();

  const style = {
    borderRadius: '.4em',
    maxHeight: '1.5em',
    margin: '0 .15em',
  };

  return <img className={css(style)} alt={name} src={image} />;
}
Avatar.propTypes = {
  image: string.isRequired,
  name: string,
};
Avatar.defaultProps = {
  name: '',
};
