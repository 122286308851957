import { call, put, select } from 'redux-saga/effects';

import i18n from '../../../../../i18n';
import { setData } from '../../actions';
import { getNotifyOnReady } from '../../selectors/page';
import { MESSAGE_RECEIVED } from './actionTypes';

const MATCH_RESOURCE_TYPE = 'MATCH';
const MATCH_STARTED_EVENT = 'STATUS_GAME_RUNNING';

export default function* messageHandler(eventData) {
  const { type, payload } = eventData;

  if (type !== MESSAGE_RECEIVED) return;

  const {
    message: { resource, data, event },
  } = payload;

  if (resource !== MATCH_RESOURCE_TYPE || data === undefined) return;

  if (event === MATCH_STARTED_EVENT) {
    yield call(notifyMatchStarted);
  }

  yield put(setData({ data }));
}

function* notifyMatchStarted() {
  const notifyOnReady = yield select(getNotifyOnReady);

  if (!notifyOnReady) return;

  yield call(displayMatchStartedNotification);
}

function displayMatchStartedNotification() {
  const title = i18n.t('platform.lobby.notifications.matchStarted.title');
  const body = i18n.t('platform.lobby.notifications.matchStarted.body');

  new Notification(title, {
    body,
    requireInteraction: true,
    icon: '/logo512.png',
  });
}
