import React from 'react';
import { Spinner, Button, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';

export default function Actions({
  className = '',
  leaving = false,
  onLeave = () => {},
  onStart = () => {},
  starting = false,
  unstartable = false,
}) {
  const { t } = useTranslation();

  const leaveText = leaving
    ? 'platform.lobby.actions.leaving'
    : 'platform.lobby.actions.leave';
  const startText = starting
    ? 'platform.lobby.actions.starting'
    : 'platform.lobby.actions.start';

  return (
    <Row className={className}>
      <Col xs='auto'>
        <Button color='danger' outline onClick={onLeave} disabled={leaving}>
          {leaving && <Spinner size='sm' />} {t(leaveText)}{' '}
          <FontAwesomeIcon icon={faDoorOpen} />
        </Button>
      </Col>
      <Col xs='auto'>
        <Button
          id='start-button'
          color='success'
          disabled={unstartable || starting}
          onClick={onStart}
        >
          {starting && <Spinner size='sm' />} {t(startText)}
        </Button>
      </Col>
    </Row>
  );
}
