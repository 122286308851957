import React from 'react';
import { Container, Modal, ModalBody } from 'reactstrap';

import Loading from './Loading';
import ConnectionInfo from './ConnectionInfo';

export default function ServerModal({
  abortable,
  aborting,
  backdrop = 'static',
  className = '',
  keyboard = false,
  loading = false,
  onAbort,
  onNotifyOnReadyChange = () => {},
  notifyOnReady = false,
  open = false,
  serverInfo = {},
}) {
  const { ipAddress, port, password } = serverInfo;

  return (
    <Modal
      className={className}
      isOpen={open}
      backdrop={backdrop}
      keyboard={keyboard}
    >
      <ModalBody>
        <Container>
          {loading ? (
            <Loading
              abortable={abortable}
              aborting={aborting}
              onAbort={onAbort}
              onNotifyOnReadyChange={onNotifyOnReadyChange}
              notifyOnReady={notifyOnReady}
            />
          ) : (
            <ConnectionInfo
              abortable={abortable}
              aborting={aborting}
              ipAddress={ipAddress}
              password={password}
              onAbort={onAbort}
              port={port}
            />
          )}
        </Container>
      </ModalBody>
    </Modal>
  );
}
