export const ABORT = 'APP/LOBBY/ABORT';
export const CHANGE_MAP = 'APP/LOBBY/CHANGE_MAP';
export const CHANGE_TEAM = 'APP/LOBBY/CHANGE_TEAM';
export const CHANGE_USER_TEAM = 'APP/LOBBY/CHANGE_USER_TEAM';
export const CLEAR_DATA = 'APP/LOBBY/CLEAR_DATA';
export const CLEAR_JOIN_ERROR = 'APP/LOBBY/CLEAR_JOIN_ERROR';
export const CREATE = 'APP/LOBBY/CREATE';
export const FETCH_DATA = 'APP/LOBBY/FETCH_DATA';
export const JOIN = 'APP/LOBBY/JOIN';
export const JOIN_FROM_CODE_INPUT = 'APP/LOBBY/JOIN_FROM_CODE_INPUT';
export const JOINED = 'APP/LOBBY/JOINED';
export const LEAVE = 'APP/LOBBY/LEAVE';
export const PAGE_LOADED = 'APP/LOBBY/PAGE_LOADED';
export const PAGE_UNLOADED = 'APP/LOBBY/PAGE_UNLOADED';
export const UPDATE_RATING = 'APP/LOBBY/UPDATE_RATING';
export const REMOVE_USER = 'APP/LOBBY/REMOVE_USER';
export const RESET = 'APP/LOBBY/RESET';
export const SET_ABORTING = 'APP/LOBBY/SET_ABORTING';
export const SET_CHANGING_MAP = 'APP/LOBBY/SET_CHANGING_MAP';
export const SET_CHANGING_TEAM = 'APP/LOBBY/SET_CHANGING_TEAM';
export const SET_CHANGING_USER_TEAM = 'APP/LOBBY/SET_CHANGING_USER_TEAM';
export const SET_CREATING = 'APP/LOBBY/SET_CREATING';
export const SET_DATA = 'APP/LOBBY/SET_DATA';
export const SET_FETCHING = 'APP/LOBBY/SET_FETCHING';
export const SET_JOIN_ERROR = 'APP/LOBBY/SET_JOIN_ERROR';
export const SET_JOINING = 'APP/LOBBY/SET_JOINING';
export const SET_LEAVING = 'APP/LOBBY/SET_LEAVING';
export const SET_LOADING = 'APP/LOBBY/SET_LOADING';
export const SET_LOCAL_RATING = 'APP/LOBBY/SET_LOCAL_RATING';
export const SET_REMOVING_USER = 'APP/LOBBY/SET_REMOVING_USER';
export const SET_STARTING_MATCH = 'APP/LOBBY/SET_STARTING_MATCH';
export const SET_SUBSCRIBED = 'APP/LOBBY/SET_SUBSCRIBED';
export const SET_UPDATING_RATING = 'APP/LOBBY/SET_UPDATING_RATING';
export const START_MATCH = 'APP/LOBBY/START_MATCH';
export const SUBSCRIBE = 'APP/LOBBY/SUBSCRIBE';
export const UNSUBSCRIBE = 'APP/LOBBY/UNSUBSCRIBE';
