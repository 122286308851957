import React, { useEffect } from 'react';
import { Row, Container, Col } from 'reactstrap';
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { CreateLobby, JoinLobby, LobbyList, Or } from './components';
import { create, joinFromCodeInput } from '../lobby/store/actions';
import { getCreating } from '../lobby/store/selectors';
import { getOngoingLobbies } from './store/selectors/page';
import { load } from './store/actions';

export function Page({
  creatingLobby,
  onCreateLobby,
  ongoingLobbies,
  onJoinLobby,
  onLoad,
}) {
  const { t } = useTranslation();
  useEffect(() => {
    onLoad();

    return () => {};
  }, [onLoad]);

  return (
    <Container className='d-flex flex-column'>
      <Alert color='danger' className='align-self-center d-inline'>
        {<Trans t={t} i18nKey='skinsAlert' />}
      </Alert>
      <Alert color='danger' className='align-self-center d-inline'>
        {<Trans t={t} i18nKey='answerFormsAlert.title' />}{' '}
        <a
          href={t('answerFormsAlert.formsUrl')}
          target='_blank'
          rel='noopener noreferrer'
        >
          {t('answerFormsAlert.formsUrlLabel')}
        </a>
      </Alert>
      <div>
        {!isEmpty(ongoingLobbies) && (
          <Row className='my-5 justify-content-center'>
            <LobbyList lobbies={ongoingLobbies} />
          </Row>
        )}
        <Row className='my-5 align-items-center justify-content-center'>
          <Col md='auto'>
            <JoinLobby onJoin={onJoinLobby} />
          </Col>

          <Col md='auto'>
            <Or />
          </Col>

          <Col md='auto'>
            <CreateLobby creating={creatingLobby} onCreate={onCreateLobby} />
          </Col>
        </Row>
      </div>
    </Container>
  );
}
Page.propTypes = {
  creatingLobby: bool,
  onCreateLobby: func,
  ongoingLobbies: LobbyList.propTypes.lobbies,
  onJoinLobby: func,
  onLoad: func,
};
Page.defaultProps = {
  creatingLobby: false,
  onCreateLobby: () => {},
  ongoingLobbies: [],
  onJoinLobby: () => {},
  onLoad: () => {},
};

const mapStateToProps = (state) => ({
  creatingLobby: getCreating(state),
  ongoingLobbies: getOngoingLobbies(state),
});
const mapDispatchToProps = (dispatch) => ({
  onCreateLobby: () => dispatch(create()),
  onJoinLobby: (lobbyId) => dispatch(joinFromCodeInput({ code: lobbyId })),
  onLoad: () => dispatch(load()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Page);
