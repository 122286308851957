import spawnAllSagas from '../../../../utils/spawnAllSagas';

import { watch as watchRedirectToPlanCheckout } from './redirectToPlanCheckout';
import { watch as watchManageSubscription } from './manageSubscription';
import watchHooks from './hooks';

export default spawnAllSagas([
  watchRedirectToPlanCheckout,
  watchManageSubscription,
  watchHooks,
]);
