import { call, put, takeEvery, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { REMOVE_USER } from '../actionTypes';
import { setData, setRemovingUser } from '../actions';
import { getLobbyId } from '../selectors';
import { updateLobbyKick } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';

export default function* removeUser({ payload: { id: userId } }) {
  const matchId = yield select(getLobbyId);

  yield put(setRemovingUser({ removing: true, userId }));

  try {
    const { data } = yield call(consistentlyRequest, updateLobbyKick, {
      matchId,
      userId,
    });

    yield put(setData({ data }));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.removeUser'));
  } finally {
    yield put(setRemovingUser({ removing: false, userId }));
  }
}

export function* watchRemoveUser() {
  yield takeEvery(REMOVE_USER, removeUser);
}
