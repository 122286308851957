import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { FETCH_DATA } from '../actionTypes';
import { setData, setFetching } from '../actions';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { getMatchById } from '../../../../apis/server';
import i18n from '../../../../i18n';

export default function* fetchData({ payload: { id } }) {
  yield put(setFetching({ fetching: true }));

  try {
    const { data } = yield call(consistentlyRequest, getMatchById, {
      matchId: id,
    });

    yield put(setData({ data }));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.fetchData'));
  } finally {
    yield put(setFetching({ fetching: false }));
  }
}

export function* watchFetchData() {
  yield takeEvery(FETCH_DATA, fetchData);
}
