import { toast } from 'react-toastify';
import {
  takeEvery,
  call,
  all,
  put,
  select,
  debounce,
} from 'redux-saga/effects';
import { addRating } from '../../../../apis/server';
import i18n from '../../../../i18n';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { setData, setLocalRating, setUpdatingRating } from '../actions';
import { UPDATE_RATING } from '../actionTypes';
import { getLobbyId } from '../selectors';
import { getRating, getLocalRating } from '../selectors/page';

export const DEBOUNCE_TIME = 800;
const REQUEST_RATING_UPDATE = 'APP/LOBBY/SAGAS/REQUEST_RATING_UPDATE';

export default function* updateRating({ rating }) {
  const previousRating = yield select(getRating);
  yield put(setLocalRating({ localRating: rating }));

  const scoreChanged = previousRating.score !== rating.score;

  if (scoreChanged) {
    yield call(requestRatingUpdate, { rating });
    return;
  }

  yield put({ type: REQUEST_RATING_UPDATE, payload: { rating } });
}

function* requestRatingUpdate({ rating }) {
  const matchId = yield select(getLobbyId);

  yield put(setUpdatingRating({ updatingRating: true }));

  try {
    const { score, message } = rating;
    const { data } = yield call(consistentlyRequest, addRating, {
      matchId,
      score,
      message,
    });

    yield put(setData({ data }));

    const localRating = yield select(getLocalRating);
    if (ratingsEqual(localRating, rating)) {
      yield put(setLocalRating({ localRating: undefined }));
    }
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.updateRating'));
  } finally {
    yield put(setUpdatingRating({ updatingRating: false }));
  }
}

const ratingsEqual = (a, b) => a.score === b.score && a.message === b.message;

export function* watchUpdateRating() {
  yield all([
    debounce(DEBOUNCE_TIME, REQUEST_RATING_UPDATE, function* ({
      payload: { rating },
    }) {
      yield call(requestRatingUpdate, { rating });
    }),
    takeEvery(UPDATE_RATING, function* ({ payload: { rating } }) {
      yield call(updateRating, { rating });
    }),
  ]);
}
