import React from 'react';
import { useTranslation } from 'react-i18next';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import PlanCard from './PlanCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

export default function MembershipPlanCard({
  disabled = false,
  onSubscribe = () => {},
  price = 0,
  type = 'free',
}) {
  const { t, i18n } = useTranslation();

  const currentPerksKey = `membership.subscriptions.${type}.perks`;
  const futurePerksKey = `membership.subscriptions.${type}.futurePerks`;

  const currentPerks = (i18n.exists(currentPerksKey)
    ? t(currentPerksKey, {
        returnObjects: true,
      })
    : []
  ).map((perk) => ({ icon: faCheck, text: perk }));
  const futurePerks = (i18n.exists(futurePerksKey)
    ? t(futurePerksKey, {
        returnObjects: true,
      })
    : []
  ).map((perk) => ({ icon: faCalendar, text: perk }));

  return (
    <PlanCard
      name={t(`membership.subscriptions.${type}.title`)}
      billingInterval={t('membership.subscriptions.month')}
      tagline={t(`membership.subscriptions.${type}.subtitle`)}
      price={price}
      buttonColor={'primary'}
      disabled={disabled}
      onSubscribe={onSubscribe}
      buttonText={
        <>
          {t(
            disabled
              ? 'membership.subscriptions.comingSoon'
              : 'membership.subscriptions.tryNow'
          )}{' '}
          {!disabled && <FontAwesomeIcon icon={faSteam} />}
        </>
      }
      perks={currentPerks.concat(futurePerks)}
    />
  );
}
