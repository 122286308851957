import React from 'react';
import {
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  Input,
  Label,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';

import {
  faThumbsUp as solidThumbsUp,
  faThumbsDown as solidThumbsDown,
} from '@fortawesome/free-solid-svg-icons';
import {
  faThumbsUp as regularThumbsUp,
  faThumbsDown as regularThumbsDown,
} from '@fortawesome/free-regular-svg-icons';

const THUMBS_DOWN_SCORE = 0;
const THUMBS_UP_SCORE = 10;

export const DEFAULT_RATING = {
  score: undefined,
  message: '',
};

export default function Rating({
  className = '',
  loading = false,
  value = DEFAULT_RATING,
  onChange = (_rating) => {},
}) {
  const { score, message } = value;

  const { t } = useTranslation();
  const { css } = useFela();

  const isNegative = score === 0;
  const isPositive = score === 10;

  const thumbsDown = isNegative ? solidThumbsDown : regularThumbsDown;
  const thumbsUp = isPositive ? solidThumbsUp : regularThumbsUp;

  const hasScore = typeof score === 'number';
  const showMessage = hasScore || message !== '';

  const onScoreChange = (score) => onChange({ score, message });
  const onMessageChange = ({ currentTarget: { value: message } }) =>
    onChange({ score, message });

  const onThumbsDown = () => onScoreChange(THUMBS_DOWN_SCORE);
  const onThumbsUp = () => onScoreChange(THUMBS_UP_SCORE);

  return (
    <Form className={`${className} ${css(styles.container)}`}>
      <FormGroup>
        <Label for='rating'>
          {t('platform.lobby.modal.finished.rating.howDidItGo')}
        </Label>
        <div className={`${css(styles.thumbsButtonContainer)} mx-2`}>
          <ButtonGroup id='rating'>
            <Button
              outline
              size='sm'
              active={isNegative}
              onClick={onThumbsDown}
            >
              <FontAwesomeIcon icon={thumbsDown} flip='horizontal' />
            </Button>
            <Button outline size='sm' active={isPositive} onClick={onThumbsUp}>
              <FontAwesomeIcon icon={thumbsUp} />
            </Button>
          </ButtonGroup>
        </div>
      </FormGroup>
      {showMessage && (
        <FormGroup>
          <div className={css(styles.textAreaContainer)}>
            <Input
              type='textarea'
              value={message}
              onChange={onMessageChange}
              placeholder={t(
                'platform.lobby.modal.finished.rating.howCanWeImprove'
              )}
            />
            {loading && (
              <div className={css(styles.spinnerContainer)}>
                <Spinner size='sm' color='success' />
              </div>
            )}
          </div>
        </FormGroup>
      )}
    </Form>
  );
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
    // textAlign: 'center',
  },
  thumbsButtonContainer: {
    display: 'inline-block',
    // justifyContent: 'center',
    // alignItems: 'center',
    // flexDirection: 'column',
    // width: '100%',
  },
  spinnerContainer: {
    position: 'absolute',
    bottom: '.2em',
    right: '.25em',
  },
  textAreaContainer: {
    position: 'relative',
  },
};
