export default [
  {
    id: 'de_dust2',
    map: 'de_dust2',
    name: 'Dust 2',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_inferno',
    map: 'de_inferno',
    name: 'Inferno',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_inferno_old',
    map: 'de_inferno_old',
    name: 'Inferno Old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_mirage',
    map: 'de_mirage',
    name: 'Mirage',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_nuke',
    map: 'de_nuke',
    name: 'Nuke',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_nuke_old',
    map: 'de_nuke_old',
    name: 'Nuke Old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_overpass',
    map: 'de_overpass',
    name: 'Overpass',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_train',
    map: 'de_train',
    name: 'Train',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_train_old',
    map: 'de_train_old',
    name: 'Train old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_vertigo',
    map: 'de_vertigo',
    name: 'Vertigo',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_cache',
    map: 'de_cache',
    name: 'Cache',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_cache_old',
    map: 'de_cache_old',
    name: 'Cache Old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_cbble_classic',
    map: 'de_cbble_classic',
    name: 'Cobblestone Old',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'aim_ak-colt',
    map: 'aim_ak-colt',
    name: 'Aim Ak Colt',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'awp_india',
    map: 'awp_india',
    name: 'AWP India',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'fy_pool_day',
    map: 'fy_pool_day',
    name: 'Pool Day',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_cbble',
    map: 'de_cbble',
    name: 'Cobblestone',
    category: 'COMPETITIVE',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_aztec',
    map: 'de_aztec',
    name: 'Aztec',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'cs_assault',
    map: 'cs_assault',
    name: 'Assault',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_ancient',
    map: 'de_ancient',
    name: 'Ancient',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_engage',
    map: 'de_engage',
    name: 'Engage',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'cs_apollo',
    map: 'cs_apollo',
    name: 'Apollo',
    category: 'COMPETITIVE_EXTRA',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'he_glass_2016',
    map: 'he_glass_2016',
    name: 'HE Glass',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'cs_rio',
    map: 'cs_rio',
    name: 'CS Rio',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_bikinibottom',
    map: 'de_bikinibottom',
    name: 'Bikini Bottom',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_not_mirage',
    map: 'de_not_mirage',
    name: 'Not Mirage',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'aim_blast',
    map: 'aim_blast',
    name: 'BLAST Stand-Off',
    category: '3V3',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {},
  },
  {
    id: 'de_inferno_mirror',
    map: 'de_inferno_mirror',
    name: 'Inferno Mirror',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_mirage_mirror',
    map: 'de_mirage_mirror',
    name: 'Mirage Mirror',
    category: '4_FUN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_lake',
    map: 'de_lake',
    name: 'Lake',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_safehouse',
    map: 'de_safehouse',
    name: 'Safe House',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {},
  },
  {
    id: 'de_shortdust',
    map: 'de_shortdust',
    name: 'Short Dust',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_guard',
    map: 'de_guard',
    name: 'Guard',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_elysion',
    map: 'de_elysion',
    name: 'Elysion',
    category: 'WINGMAN',
    tags: [],
    constraints: {
      playerCount: {
        min: 1,
        max: 5,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_inferno',
    map: 'de_inferno',
    name: 'Short Inferno',
    category: 'WINGMAN',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_cbble',
    map: 'de_cbble',
    name: 'Short Cobblestone',
    category: 'WINGMAN',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_nuke',
    map: 'de_shortnuke',
    name: 'Short Nuke',
    category: 'WINGMAN',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_train',
    map: 'de_train',
    name: 'Short Train',
    category: 'WINGMAN',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_vertigo',
    map: 'de_vertigo',
    name: 'Short Vertigo',
    category: 'WINGMAN',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
  {
    id: 'de_wingman_overpass',
    map: 'de_overpass',
    name: 'Short Overpass',
    category: 'WINGMAN',
    tags: ['NEW'],
    constraints: {
      playerCount: {
        min: 1,
        max: 3,
      },
    },
    extra: {
      mapType: 'WINGMAN',
    },
  },
];
