import { call, put, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';

import { joinLobby } from '../../../../apis/server';
import { UNKNOWN } from '../../../../apis/server/errorCodes';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { JOIN } from '../actionTypes';
import { joined, setJoining, setJoinError } from '../actions';

export default function* join({ payload: { id } }) {
  yield put(setJoining({ joining: true }));

  try {
    const { data } = yield call(consistentlyRequest, joinLobby, { id });

    yield put(joined({ data }));
  } catch (error) {
    const code = get(error, 'response.data.code', UNKNOWN);

    yield put(setJoinError({ error: code }));
  } finally {
    yield put(setJoining({ joining: false }));
  }
}

export function* watchJoin() {
  yield takeEvery(JOIN, join);
}
