import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { bool, string, func } from 'prop-types';

export default function CreateLobby({ className, creating, onCreate }) {
  const { t } = useTranslation();

  const buttonText = creating
    ? 'platform.home.actions.createLobby.creating'
    : 'platform.home.actions.createLobby.create';

  return (
    <div className={className}>
      <h2>{t('platform.home.actions.createLobby.createLobby')}</h2>
      <div>
        <Button color='success' onClick={onCreate} disabled={creating}>
          {creating && <Spinner size='sm' />} {t(buttonText)}
        </Button>
      </div>
    </div>
  );
}
CreateLobby.propTypes = {
  className: string,
  creating: bool,
  onCreate: func.isRequired,
};
CreateLobby.defaultProps = {
  className: '',
  creating: false,
};
