import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { find } from 'lodash';

import { CHANGE_MAP } from '../actionTypes';
import { setChangingMap, setData } from '../actions';
import { getLobbyId } from '../selectors';
import { getCurrentMap } from '../selectors/room';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import { updateLobbyChangeMap } from '../../../../apis/server';
import i18n from '../../../../i18n';
import availableMaps from '../../helper/availableMaps';

export default function* changeMap({ payload: { id } }) {
  const currentMapId = yield select(getCurrentMap);

  if (id === currentMapId) return;

  const matchId = yield select(getLobbyId);

  const {
    map,
    extra: { mapType },
  } = find(availableMaps, { id });

  yield put(setChangingMap({ changingMap: true }));

  try {
    const { data } = yield call(consistentlyRequest, updateLobbyChangeMap, {
      map,
      matchId,
      mapType,
    });

    yield put(setData({ data }));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.changeMap'));
  } finally {
    yield put(setChangingMap({ changingMap: false }));
  }
}

export function* watchChangeMap() {
  yield takeEvery(CHANGE_MAP, changeMap);
}
