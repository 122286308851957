import spawnAllSagas from '../../../../utils/spawnAllSagas';

import { watchAbort } from './abort';
import { watchChangeMap } from './changeMap';
import { watchChangeTeam } from './changeTeam';
import { watchChangeUserTeam } from './changeUserTeam';
import { watchCreate } from './create';
import { watchFetchData } from './fetchData';
import { watchJoin } from './join';
import { watchJoined } from './joined';
import { watchJoinFromCodeInput } from './joinFromCodeInput';
import { watchLeave } from './leave';
import { watchPageLoaded } from './pageLoaded';
import { watchPageUnloaded } from './pageUnloaded';
import { watchRemoveUser } from './removeUser';
import { watchStartMatch } from './startMatch';
import { watch as subscribe } from './subscribe';
import { watch as unsubscribe } from './unsubscribe';
import { watchUpdateRating } from './updateRating';

export default spawnAllSagas([
  watchAbort,
  watchChangeMap,
  watchChangeTeam,
  watchChangeUserTeam,
  watchCreate,
  watchFetchData,
  watchJoin,
  watchJoined,
  watchJoinFromCodeInput,
  watchLeave,
  watchPageLoaded,
  watchPageUnloaded,
  watchRemoveUser,
  watchStartMatch,
  subscribe,
  unsubscribe,
  watchUpdateRating,
]);
