import React from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import { isEmpty } from 'lodash';

import MatchOptions from './match-options';
import Team from './Team';
import Actions from './Actions';

export default function Room({
  availableMaps,
  changingMap = false,
  changingTeams = false,
  className = '',
  currentMap,
  finished,
  finishing,
  leaving = false,
  onJoinTeam = (_id) => {},
  onLeave = () => {},
  onMapChange = (_map) => {},
  onRemoveUser = (_id) => {},
  onSwapUser = (_id) => {},
  onStart = () => {},
  starting = false,
  optionsDisabled = false,
  teams,
  unstartable = false,
  unstartableReasons = [],
  url,
}) {
  const [leftTeam, rightTeam] = teams;
  const shouldShowUnstartableAlert =
    unstartable && !isEmpty(unstartableReasons);

  if (finished || finishing) return null;

  const renderTeam = ({ id, joinable, name, reverse, users }) => (
    <Team
      className='my-4 my-md-0'
      joinable={joinable}
      joining={changingTeams}
      name={name}
      onJoin={() => onJoinTeam(id)}
      onRemoveUser={onRemoveUser}
      onSwapUser={onSwapUser}
      users={users}
      reverse={reverse}
    />
  );

  return (
    <Container className={className}>
      <Row className='justify-content-center my-4'>
        <MatchOptions
          changingMap={changingMap}
          currentMapId={currentMap}
          disabled={optionsDisabled}
          lobbyUrl={url}
          maps={availableMaps}
          onMapChange={onMapChange}
        />
      </Row>
      <Row className='justify-content-center my-4'>
        <Col md='6' xl='5'>
          {renderTeam({ ...leftTeam, reverse: false })}
        </Col>
        <Col md='6' xl='5'>
          {renderTeam({ ...rightTeam, reverse: true })}
        </Col>
      </Row>
      {shouldShowUnstartableAlert && (
        <Row className='justify-content-center'>
          <Alert color='warning'>
            <ul className='mb-0 ml-0 pl-4'>
              {unstartableReasons.map((reason) => (
                <li key={reason}>{reason}</li>
              ))}
            </ul>
          </Alert>
        </Row>
      )}
      <Row className='justify-content-center my-4'>
        <Actions
          leaving={leaving}
          onLeave={onLeave}
          onStart={onStart}
          starting={starting}
          unstartable={unstartable}
        />
      </Row>
    </Container>
  );
}
