import React from 'react';
import { Spinner, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import MapSelect from './MapSelect';
import CopyInput from '../CopyInput';

const URL_PROTOCOL = process.env.REACT_APP_URL_PROTOCOL;

export default function MatchOptions({
  changingMap = false,
  className = '',
  currentMapId,
  disabled = false,
  lobbyUrl,
  maps = [],
  onMapChange = () => {},
}) {
  const { t } = useTranslation();

  const processLobbyUrl = (url) => `${URL_PROTOCOL}${url}`;

  return (
    <div className={className}>
      <Row>
        <Col sm='12' md='auto'>
          <div className='form-group'>
            <label htmlFor='map-select'>
              {t('platform.lobby.options.map.label')}{' '}
              {changingMap && <Spinner size='sm' />}
            </label>
            <MapSelect
              currentMapId={currentMapId}
              disabled={disabled || changingMap}
              maps={maps}
              onChange={onMapChange}
            />
          </div>
        </Col>
        <Col sm='12' md='auto'>
          <div className='form-group'>
            <label htmlFor='lobby-url'>
              {t('platform.lobby.options.lobbyUrl.label')}
            </label>
            <CopyInput value={lobbyUrl} processValue={processLobbyUrl} />
          </div>
        </Col>
      </Row>
    </div>
  );
}
