import { connect } from 'react-redux';

import { signOut } from '../meta/store/actions';
import {
  getUserNickname,
  getUserSmallAvatar,
} from '../../authentication/store/selectors';
import { getBadgeInfo } from '../store/selectors/navbar';
// import { manageSubscription } from '../membership/store/actions';

import Navbar from '../components/Navbar';
import {
  getSubscriptionManagementLoading,
  // getUserMembershipTier,
} from '../membership/store/selectors';

const mapStateToProps = (state) => ({
  username: getUserNickname(state),
  avatarUrl: getUserSmallAvatar(state),
  ...getBadgeInfo(state),
  // showUpgradeCta: getUserMembershipTier(state) !== 'gold',
  loading: getSubscriptionManagementLoading(state),
});
const mapDispatchToProps = (dispatch) => ({
  onSignOut: () => dispatch(signOut()),
  // onManageSubscription: () => dispatch(manageSubscription()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
