import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import { useTranslation, Trans } from 'react-i18next';

import badgeInfo from '../helpers/badgeInfo';
import { useFela } from 'react-fela';

export default function Badge({
  badge = {},
  color = undefined,
  className = '',
  noPopover = false,
  popoverPlacement = 'bottom',
}) {
  const { name } = badge;

  const [iconId] = useState(() => `icon-${uuid()}`);
  const { t } = useTranslation();
  const { css } = useFela();

  if (!(name in badgeInfo)) return null;

  const { icon, title, color: badgeInfoColor } = badgeInfo[name];

  const effectiveColor = color || badgeInfoColor;
  const translationValues = {
    badge: {
      ...badge,
      title,
      localTitle: t(`badges.${title}.title`, { badge: { ...badge, title } }),
    },
  };

  return (
    <div className={className}>
      <FontAwesomeIcon
        id={iconId}
        size='sm'
        color={effectiveColor}
        icon={icon}
      />
      {!noPopover && (
        <UncontrolledPopover
          trigger='hover'
          placement={popoverPlacement}
          target={iconId}
        >
          <PopoverHeader>
            {t('badge.popover.title', translationValues)}
          </PopoverHeader>
          <PopoverBody>
            <div className={css(styles.popover.container)}>
              <div className={css(styles.popover.item)}>
                <FontAwesomeIcon icon={icon} size='lg' />
              </div>
              <div className={css(styles.popover.item)}>
                <div>
                  <Trans
                    i18nKey={`badges.${title}.description`}
                    components={{
                      strike: <strike />,
                      italic: <em />,
                      bold: <strong />,
                    }}
                    values={translationValues}
                  />
                </div>
                <div>
                  <em>
                    <small>
                      {t(`badges.${title}.tagline`, translationValues)}
                    </small>
                  </em>
                </div>
              </div>
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </div>
  );
}

const styles = {
  popover: {
    container: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
    item: {
      margin: '0.5em',
    },
  },
};
