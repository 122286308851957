import React from 'react';
import { useFela } from 'react-fela';

import Avatar from './Avatar';
import Badge from './Badge';

export default function BadgedAvatar({ badge, className = '', ...props }) {
  const { css } = useFela();

  return (
    <div className={`${className} ${css(styles.container)}`}>
      <Avatar {...props} />
      <Badge badge={badge} className={css(styles.icon)} />
    </div>
  );
}

const styles = {
  container: {
    display: 'inline',
  },
  icon: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '50%',
    width: '22px',
    height: '22px',
    marginLeft: '-12px',
    bottom: '2px',
    position: 'absolute',
  },
};
