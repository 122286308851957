import { call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import { LEAVE } from '../actionTypes';
import { reset, setLeaving } from '../actions';
import { getLobbyId } from '../selectors';
import { updateLobbyQuit } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';
import unsubscribe from './unsubscribe';

const HOME_PATH = '/';

export default function* leave() {
  const matchId = yield select(getLobbyId);

  yield put(setLeaving({ leaving: true }));

  yield call(unsubscribe);
  yield put(reset());

  let successful = false;
  try {
    yield call(consistentlyRequest, updateLobbyQuit, { matchId });
    successful = true;
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.leave'));
  } finally {
    yield put(setLeaving({ leaving: false }));
  }

  if (successful) {
    yield put(push(HOME_PATH));
  }
}

export function* watchLeave() {
  yield takeEvery(LEAVE, leave);
}
