import {
  CLEAR_DATA,
  CLEAR_JOIN_ERROR,
  RESET,
  SET_ABORTING,
  SET_CHANGING_MAP,
  SET_CHANGING_TEAM,
  SET_CHANGING_USER_TEAM,
  SET_CREATING,
  SET_DATA,
  SET_FETCHING,
  SET_JOIN_ERROR,
  SET_JOINING,
  SET_LEAVING,
  SET_LOADING,
  SET_REMOVING_USER,
  SET_STARTING_MATCH,
  SET_SUBSCRIBED,
  SET_LOCAL_RATING,
  SET_UPDATING_RATING,
} from './actionTypes';

const initialState = {
  aborting: false,
  changingMap: false,
  changingTeam: false,
  changingUserTeam: {},
  creating: false,
  data: undefined,
  fetching: false,
  joinError: undefined,
  joining: true,
  leaving: false,
  removingUser: {},
  startingMatch: false,
  subscribed: false,
  loading: false,
  localRating: undefined,
  updatingRating: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_DATA:
      return clearData(state, action);
    case CLEAR_JOIN_ERROR:
      return clearJoinError(state, action);
    case RESET:
      return initialState;
    case SET_ABORTING:
      return setAborting(state, action);
    case SET_CHANGING_MAP:
      return setChangingMap(state, action);
    case SET_CHANGING_TEAM:
      return setChangingTeam(state, action);
    case SET_CHANGING_USER_TEAM:
      return setChangingUserTeam(state, action);
    case SET_CREATING:
      return setCreating(state, action);
    case SET_DATA:
      return setData(state, action);
    case SET_FETCHING:
      return setFetching(state, action);
    case SET_JOIN_ERROR:
      return setJoinError(state, action);
    case SET_JOINING:
      return setJoining(state, action);
    case SET_LEAVING:
      return setLeaving(state, action);
    case SET_LOADING:
      return setLoading(state, action);
    case SET_LOCAL_RATING:
      return setLocalRating(state, action);
    case SET_REMOVING_USER:
      return setRemovingUser(state, action);
    case SET_STARTING_MATCH:
      return setStartingMatch(state, action);
    case SET_SUBSCRIBED:
      return setSubscribed(state, action);
    case SET_UPDATING_RATING:
      return setUpdatingRating(state, action);
    default:
      return state;
  }
};

const clearData = (state, _action) => ({
  ...state,
  data: undefined,
});

const clearJoinError = (state, _action) => ({
  ...state,
  joinError: undefined,
});

const setAborting = (state, action) => {
  const {
    payload: { aborting },
  } = action;

  return {
    ...state,
    aborting,
  };
};

const setChangingMap = (state, action) => {
  const {
    payload: { changingMap },
  } = action;

  return {
    ...state,
    changingMap,
  };
};

const setChangingTeam = (state, action) => {
  const {
    payload: { changingTeam },
  } = action;

  return {
    ...state,
    changingTeam,
  };
};

const setChangingUserTeam = (state, action) => {
  const {
    payload: { changing, userId },
  } = action;

  return {
    ...state,
    changingUserTeam: {
      ...state.changingUserTeam,
      [userId]: changing,
    },
  };
};

const setCreating = (state, action) => {
  const {
    payload: { creating },
  } = action;

  return {
    ...state,
    creating,
  };
};

const setData = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    data,
  };
};

const setFetching = (state, action) => {
  const {
    payload: { fetching },
  } = action;

  return {
    ...state,
    fetching,
  };
};

const setJoinError = (state, action) => {
  const {
    payload: { error },
  } = action;

  return {
    ...state,
    joinError: error,
  };
};

const setJoining = (state, action) => {
  const {
    payload: { joining },
  } = action;

  return {
    ...state,
    joining,
  };
};

const setLeaving = (state, action) => {
  const {
    payload: { leaving },
  } = action;

  return {
    ...state,
    leaving,
  };
};

const setLoading = (state, action) => {
  const {
    payload: { loading },
  } = action;

  return {
    ...state,
    loading,
  };
};

const setLocalRating = (state, action) => {
  const {
    payload: { localRating },
  } = action;

  return {
    ...state,
    localRating,
  };
};

const setRemovingUser = (state, action) => {
  const {
    payload: { removing, userId },
  } = action;

  return {
    ...state,
    removingUser: {
      ...state.removingUser,
      [userId]: removing,
    },
  };
};

const setStartingMatch = (state, action) => {
  const {
    payload: { startingMatch },
  } = action;

  return {
    ...state,
    startingMatch,
  };
};

const setSubscribed = (state, action) => {
  const {
    payload: { subscribed },
  } = action;

  return {
    ...state,
    subscribed,
  };
};

const setUpdatingRating = (state, action) => {
  const {
    payload: { updatingRating },
  } = action;

  return {
    ...state,
    updatingRating,
  };
};
