import React from 'react';
import {
  Container,
  Navbar as BootstrapNavbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  // Button,
  Spinner,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { useFela } from 'react-fela';
import { useTranslation } from 'react-i18next';

import Avatar from './Avatar';
import BadgedAvatar from './BadgedAvatar';
import logo from '../../assets/qs24.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';

export default function Navbar({
  loading = false,
  userBadge,
  userHasBadge,
  username,
  // showUpgradeCta = true,
  onSignOut,
  // onManageSubscription = () => {},
  avatarUrl,
}) {
  const { t } = useTranslation();
  const { css } = useFela();

  return (
    <div>
      <BootstrapNavbar color='light' light expand='sm'>
        <Container>
          <NavbarBrand tag={Link} to='/'>
            <img
              className={css(styles.logo)}
              src={logo}
              alt={t('navbar.logo.alt')}
            />
          </NavbarBrand>

          <Nav className='ml-auto' navbar>
            {/* {showUpgradeCta && (
              <div className='d-flex flex-column justify-content-center'>
                <Button tag={Link} to='/membership' color='primary'>
                  {t('navbar.subscribe')} <FontAwesomeIcon icon={faStar} />
                </Button>
              </div>
            )} */}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                {loading && <Spinner size='sm' />} {username}
                {userHasBadge ? (
                  <BadgedAvatar
                    alt={username}
                    badge={userBadge}
                    className={css(styles.avatar)}
                    url={avatarUrl}
                  />
                ) : (
                  <Avatar
                    alt={username}
                    className={css(styles.avatar)}
                    url={avatarUrl}
                  />
                )}
              </DropdownToggle>

              <DropdownMenu right>
                <DropdownItem tag={Link} to='/donate'>
                  <div className={css(styles.donationItem)}>
                    {t('navbar.donate')}
                  </div>
                </DropdownItem>
                {/* <DropdownItem onClick={onManageSubscription}>
                  {t('navbar.user.options.manageSubscription')}
                </DropdownItem> */}
                <DropdownItem onClick={onSignOut}>
                  {t('navbar.user.options.signOut')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </BootstrapNavbar>
    </div>
  );
}

const styles = {
  logo: {
    height: '40px',
  },
  avatar: {
    maxHeight: '40px',
    margin: '0 .5em',
  },
  donationItem: {
    color: '#856404',
  },
};
