import React, { useState } from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  Spinner,
  Collapse,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';

import CopyInput from '../CopyInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const GOTV_PORT = 27020;

export default function ConnectionInfo({
  abortable = false,
  aborting = false,
  ipAddress,
  onAbort = () => {},
  port,
  password = '',
}) {
  const { t } = useTranslation();
  const [showGotvInfo, setshowGotvInfo] = useState(false);

  const info = { ipAddress, port, password };
  // const url = generateUrl(info);
  const command = generateCommand(info);
  const gotvCommand = generateCommand({
    ...info,
    port: GOTV_PORT,
  });

  const abortText = aborting
    ? 'platform.lobby.modal.connection.aborting'
    : 'platform.lobby.modal.connection.abort';
  const gotvText = showGotvInfo
    ? 'platform.lobby.modal.connection.hideGotv'
    : 'platform.lobby.modal.connection.showGotv';

  const onToggleGotv = () => {
    setshowGotvInfo(!showGotvInfo);
  };

  return (
    <div className='p-4'>
      <Row>
        <Col>
          <h3
            style={{
              textAlign: 'center',
              fontSize: '50px',
            }}
          >
            {t('platform.lobby.modal.connection.matchStarted')}
          </h3>
        </Col>
      </Row>
      {/* <Row className='justify-content-center my-2 mt-4'>
        <Col xs='auto'>
          <a className='btn btn-success' href={url}>
            {t('platform.lobby.modal.connection.clickToConnect')}
          </a>
        </Col>
      </Row>
      <Row className='justify-content-center my-2'>
        <Col xs='auto'>
          <h5>{t('platform.lobby.modal.connection.or')}</h5>
        </Col>
      </Row> */}
      <Row className='justify-content-center my-2 mb-4'>
        <Col xs='10'>
          <CopyInput value={command} />
        </Col>
      </Row>
      <Row className='justify-content-center my-0 mb-4'>
        <Button onClick={onToggleGotv} color='link' id='gotv-toggle' size='sm'>
          <FontAwesomeIcon icon={faInfoCircle} /> {t(gotvText)}
        </Button>
        <UncontrolledPopover
          trigger='hover'
          placement='top'
          target='gotv-toggle'
        >
          <PopoverHeader>
            {t('platform.lobby.modal.gotvPopover.title')}
          </PopoverHeader>
          <PopoverBody>
            <Trans
              i18nKey='platform.lobby.modal.gotvPopover.body'
              components={{
                bold: <b />,
              }}
            />
          </PopoverBody>
        </UncontrolledPopover>
      </Row>
      <Collapse isOpen={showGotvInfo}>
        <Row className='justify-content-center my-0 mb-4'>
          <Col sm='10'>
            <CopyInput value={gotvCommand} />
          </Col>
        </Row>
      </Collapse>
      {abortable && (
        <>
          <hr />
          <Container className='d-flex justify-content-between align-items-center my-2 mt-4'>
            {t('platform.lobby.modal.connection.tired')}{' '}
            <Button onClick={onAbort} color='danger' outline size='sm'>
              {aborting && <Spinner size='sm' />} {t(abortText)}
            </Button>
          </Container>
        </>
      )}
    </div>
  );
}

// eslint-disable-next-line no-unused-vars
const generateUrl = ({ ipAddress, password, port }) => {
  const base = 'steam://connect/';
  const address = `${ipAddress}:${port}`;
  const passwordBit = isEmpty(password) ? '' : `/${password}`;

  return `${base}${address}${passwordBit}`;
};
const generateCommand = ({ ipAddress, password, port }) => {
  const address = `${ipAddress}:${port}`;

  if (isEmpty(password)) return `connect ${address}`;

  return `connect ${address}; password ${password}`;
};
