import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import {} from 'reactstrap';

import Avatar from './Avatar';

export default function Users({ users }) {
  return (
    <>
      {users.map(({ id, avatar, name }) => (
        <Avatar key={id} image={avatar} name={name} />
      ))}
    </>
  );
}
Users.propTypes = {
  users: arrayOf(
    shape({
      id: string,
      avatar: string,
      name: string,
    })
  ).isRequired,
};
Users.defaultProps = {};
