import { put, select, takeEvery } from 'redux-saga/effects';

import { JOINED } from '../actionTypes';
import { subscribe, setData } from '../actions';
import { getLobbyId } from '../selectors';

export default function* joined({ payload: { data } }) {
  yield put(setData({ data }));
  const lobbyId = yield select(getLobbyId);
  yield put(subscribe({ matchId: lobbyId }));
}

export function* watchJoined() {
  yield takeEvery(JOINED, joined);
}
