import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { CHANGE_TEAM } from '../actionTypes';
import { setChangingTeam, setData } from '../actions';
import { getLobbyId } from '../selectors';
import { getUserId } from '../../../../authentication/store/selectors';
import { updateLobbyChangeTeam } from '../../../../apis/server';
import consistentlyRequest from '../../../../utils/consistentlyRequest';
import i18n from '../../../../i18n';

export default function* changeTeam({ payload: { id: teamId } }) {
  const matchId = yield select(getLobbyId);
  const userId = yield select(getUserId);

  yield put(setChangingTeam({ changingTeam: true }));

  try {
    const { data } = yield call(consistentlyRequest, updateLobbyChangeTeam, {
      matchId,
      teamId,
      userId,
    });

    yield put(setData({ data }));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('platform.lobby.errors.sagas.changeTeam'));
  } finally {
    yield put(setChangingTeam({ changingTeam: false }));
  }
}

export function* watchChangeTeam() {
  yield takeEvery(CHANGE_TEAM, changeTeam);
}
