import { filter, find, get, map, max, min, size } from 'lodash';
import { createSelector } from 'reselect';
import i18n from '../../../../i18n';

import { getData, getLobbyShortId } from './basic';
import { getUserId } from '../../../../authentication/store/selectors';
import { getUserBadgeInfo } from '../../../store/selectors/badge';
import availableMaps from '../../helper/availableMaps';

const ADMIN_LEVEL = 'ADMIN';
export const LEFT_TEAM = 'TEAM_1';
export const RIGHT_TEAM = 'TEAM_2';
const BASE_URL = process.env.REACT_APP_BASE_LOBBY_URL;
const MINIMUM_PLAYERS_PER_TEAM = 1;
const MAXIMUM_PLAYERS_PER_TEAM = 5;

export const getOpposingTeam = (team) =>
  team === LEFT_TEAM ? RIGHT_TEAM : LEFT_TEAM;
const getPlayers = (state) => getData(state).players;
export const isPlayerAdmin = (player) => get(player, 'level') === ADMIN_LEVEL;
const getPlayerSmallAvatarUrl = (player) => get(player, 'user.avatar.small');
const getPlayerName = (player) => get(player, 'user.nickname');
const getPlayerUserId = (player) => get(player, 'user._id');
export const getPlayerTeam = (player) => player.team;
const getTeamName = (teamId) => i18n.t(`platform.lobby.team.names.${teamId}`);
const getPlayerBadgeInfo = ({ user }) => getUserBadgeInfo(user);
const mapPlayerToTeamUser = (player) => ({
  ...getPlayerBadgeInfo(player),
  admin: isPlayerAdmin(player),
  avatarUrl: getPlayerSmallAvatarUrl(player),
  id: getPlayerUserId(player),
  name: getPlayerName(player),
});
const mapTeam = (players, team, userPlayer) => {
  const userId = getPlayerUserId(userPlayer);
  const isUserAdmin = isPlayerAdmin(userPlayer);

  const teamPlayers = filter(players, { team });
  const playerIsUser = (player) => getPlayerUserId(player) === userId;
  const isPlayerControllable = (player) => isUserAdmin && !playerIsUser(player);
  const isTeamJoinable = (teamId) => teamId !== getPlayerTeam(userPlayer);

  const mapPlayer = (player) => {
    const controllable = isPlayerControllable(player);

    return {
      ...mapPlayerToTeamUser(player),
      removable: controllable,
      swappable: controllable,
    };
  };

  return {
    id: team,
    name: getTeamName(team),
    users: map(teamPlayers, mapPlayer),
    joinable: isTeamJoinable(team),
  };
};
export const getUserPlayer = createSelector(
  getUserId,
  getPlayers,
  (currentUserId, players) =>
    find(players, (player) => getPlayerUserId(player) === currentUserId)
);
const userIsAdmin = createSelector(getUserPlayer, isPlayerAdmin);

const getLeftTeam = createSelector(
  getPlayers,
  getUserPlayer,
  (players, userPlayer) => mapTeam(players, LEFT_TEAM, userPlayer)
);
const getRightTeam = createSelector(
  getPlayers,
  getUserPlayer,
  (players, userPlayer) => mapTeam(players, RIGHT_TEAM, userPlayer)
);
const getTeamUserCount = (team) => size(team.users);

export const getCurrentMap = (state) => {
  const { map, mapType } = getData(state);

  const { id } = availableMaps.find(({ map: mapId, extra }) => {
    if (map !== mapId) return false;

    if (!mapType && !extra.mapType) return true;

    return mapType === extra.mapType;
  });

  return id;
};
export const getOptionsDisabled = (state) => !userIsAdmin(state);
export const getPlayerByUserId = (state, userId) => {
  const players = getPlayers(state);
  return find(players, (player) => getPlayerUserId(player) === userId);
};
export const getTeams = createSelector(
  getLeftTeam,
  getRightTeam,
  (leftTeam, rightTeam) => [leftTeam, rightTeam]
);
const getUnstartableMapReasons = createSelector(
  getTeams,
  getCurrentMap,
  (teams, currentMap) => {
    const teamUserCounts = map(teams, getTeamUserCount);
    const smallestCount = min(teamUserCounts);
    const largestCount = max(teamUserCounts);

    const minPlayers = get(
      currentMap,
      'constraints.playerCount.min',
      MINIMUM_PLAYERS_PER_TEAM
    );
    const maxPlayers = get(
      currentMap,
      'constraints.playerCount.max',
      MAXIMUM_PLAYERS_PER_TEAM
    );

    if (smallestCount < minPlayers)
      return [
        i18n.t('platform.lobby.unstartableReasons.tooLittlePlayers', {
          count: minPlayers,
        }),
      ];

    if (largestCount > maxPlayers)
      return [
        i18n.t('platform.lobby.unstartableReasons.tooManyPlayers', {
          count: maxPlayers,
        }),
      ];

    return [];
  }
);
export const getUnstartableReasons = createSelector(
  userIsAdmin,
  getTeams,
  getUnstartableMapReasons,
  (isUserAdmin, teams, mapReasons) => {
    const reasons = [...mapReasons];

    if (!isUserAdmin)
      reasons.push(i18n.t('platform.lobby.unstartableReasons.notAdmin'));

    return reasons;
  }
);
export const getUnstartable = createSelector(
  getUnstartableReasons,
  (reasons) => reasons.length > 0
);
export const getUrl = (state) => `${BASE_URL}/${getLobbyShortId(state)}`;
