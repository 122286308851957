import { get } from 'lodash';

export const getAuthentication = (state) => state.authentication;

export const getRedirectPath = (state) => getAuthentication(state).redirectPath;

export const getToken = (state) => getAuthentication(state).token;

export const getTokenData = (state) => getAuthentication(state).tokenData;

export const getUserData = (state) => getAuthentication(state).userData;

export const getUserNickname = (state) =>
  get(getUserData(state), 'nickname', undefined);

export const getUserAvatar = (state) =>
  get(getUserData(state), 'avatar', undefined);

export const getUserSmallAvatar = (state) =>
  get(getUserData(state), 'avatar.small', undefined);

export const getUserId = (state) =>
  get(getTokenData(state), 'userId', undefined);

export const getSteamId = (state) =>
  get(getTokenData(state), 'steamId', undefined);

export const hasRedirectPath = (state) => !!getRedirectPath(state);

export const hasToken = (state) => !!getToken(state);

export const hasTokenData = (state) => !!getTokenData(state);

export const isAuthenticated = (state) => hasToken(state);
