import { get, filter, isEmpty, maxBy } from 'lodash';

import badgeInfo from '../../helpers/badgeInfo';

export const getUserBadgeInfo = (user) => {
  const badges = get(user, 'badges', []);
  const knownBadges = filter(badges, isBadgeKnown);

  const hasBadge = !isEmpty(knownBadges);
  if (!hasBadge) return { hasBadge };

  const priorityBadge = maxBy(knownBadges, 'priority');

  return {
    hasBadge,
    badge: priorityBadge,
  };
};
export const isBadgeKnown = ({ name }) => badgeInfo[name] !== undefined;
